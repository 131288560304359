@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fafafa;
}

.rti--container {
  background-color: rgb(249, 250, 251, 1) !important;
  transition: all 0.3s ease-in-out !important;
}

.rti--container:focus-within {
  border-color: transparent !important;
  box-shadow: #ff7425 0 0 0 1px !important;
}

.p-datatable-wrapper {
  min-height: 300px !important;
}

.p-datatable-emptymessage td {
  text-align: center !important;
  height: 100px;
}

.reactSelectBox {
  -webkit-appearance: none; /* Removes default chrome and safari style */
  -moz-appearance: none; /* Removes default style Firefox */
  appearance: none;
}

.custom-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black; /* example for an arrow */
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}

.swiper-pagination-bullet-active {
  background-color: #ff7700;
}

.p-datatable {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.p-datatable-wrapper {
  width: 100%;
  overflow-y: hidden;
}

.p-paginator {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 39px;
  background-color: white;
  gap: 20px;
  box-shadow: 0px 7px 13px 0px #bebebe40;
  display: flex;
}

.p-paginator-prev {
  height: 39px;
  width: 39px;
  background-color: #111111;
  border-radius: 50%;
}

.p-paginator-next {
  height: 39px;
  width: 39px;
  background-color: #111111;
  border-radius: 50%;
}

.p-icon {
  color: white;
  height: 20px;
  width: 20px;
}

.p-paginator .p-paginator-pages {
  display: flex;
  align-items: center;
  gap: 12px;
}

.p-paginator .p-paginator-page.p-highlight {
  background-color: #ff742533;
  color: #ff7425;
}

.p-paginator .p-paginator-page {
  width: 31px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.p-paginator .p-paginator-pages {
  justify-content: center;
  align-items: center;
}

.p-paginator-first {
  display: none;
}

.p-paginator-last {
  display: none;
}

.p-datatable-wrapper::-webkit-scrollbar {
  display: none;
}

@media (min-width: 400px) and (max-width: 450px) {
  .sittingMan {
    left: 30% !important;
  }
}

@media (min-width: 451px) and (max-width: 666px) {
  .sittingMan {
    left: 35% !important;
  }
}

@media (min-width: 667px) and (max-width: 767px) {
  .sittingMan {
    left: 38% !important;
  }
}

@media (min-width: 1536px) and (max-width: 2700px) {
  .sittingMan {
    left: 32% !important;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .manClimb {
    right: 40px !important;
  }
}

@media (min-width: 768px) and (max-width: 789px) {
  .manClimb {
    right: 80px !important;
  }
}

@media (min-width: 790px) and (max-width: 808px) {
  .manClimb {
    right: 90px !important;
  }
}

@media (min-width: 809px) and (max-width: 827px) {
  .manClimb {
    right: 100px !important;
  }
}

@media (min-width: 828px) and (max-width: 855px) {
  .manClimb {
    right: 20px !important;
  }
}

@media (min-width: 856px) and (max-width: 880px) {
  .manClimb {
    right: 33px !important;
  }
}

@media (min-width: 881px) and (max-width: 903px) {
  .manClimb {
    right: 45px !important;
  }
}

@media (min-width: 904px) and (max-width: 930px) {
  .manClimb {
    right: 60px !important;
  }
}

@media (min-width: 931px) and (max-width: 947px) {
  .manClimb {
    right: 70px !important;
  }
}

@media (min-width: 948px) and (max-width: 975px) {
  .manClimb {
    right: 80px !important;
  }
}

@media (min-width: 976px) and (max-width: 999px) {
  .manClimb {
    right: 95px !important;
  }
}

@media (min-width: 1000px) and (max-width: 1007px) {
  .manClimb {
    right: 105px !important;
  }
}

@media (min-width: 1008px) and (max-width: 1024px) {
  .manClimb {
    top: 155px;
    right: 30px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1065px) {
  .manClimb {
    top: auto;
    right: -20px !important;
  }
}

@media (min-width: 1066px) and (max-width: 1110px) {
  .manClimb {
    right: -5px !important;
  }
}

@media (min-width: 1111px) and (max-width: 1160px) {
  .manClimb {
    right: 5px !important;
  }
}

@media (min-width: 1161px) and (max-width: 1210px) {
  .manClimb {
    right: 20px !important;
  }
}

@media (min-width: 1211px) and (max-width: 1261px) {
  .manClimb {
    right: 30px !important;
  }
}

@media (min-width: 1262px) and (max-width: 1299px) {
  .manClimb {
    right: 43px !important;
  }
}

@media (min-width: 1300px) and (max-width: 1338px) {
  .manClimb {
    right: 50px !important;
  }
}

@media (min-width: 1339px) and (max-width: 1371px) {
  .manClimb {
    right: -30px !important;
  }
}

@media (min-width: 1372px) and (max-width: 1400px) {
  .manClimb {
    right: -20px !important;
  }
}

@media (min-width: 1401px) and (max-width: 1450px) {
  .manClimb {
    right: -10px !important;
  }
}

@media (min-width: 1451px) and (max-width: 1500px) {
  .manClimb {
    right: 0px !important;
  }
}

@media (min-width: 1501px) and (max-width: 1535px) {
  .manClimb {
    right: 10px !important;
  }
}

@media (min-width: 1536px) and (max-width: 1620px) {
  .manClimb {
    right: -70px !important;
  }
}

@media (min-width: 1621px) and (max-width: 1699px) {
  .manClimb {
    right: -45px !important;
  }
}

@media (min-width: 1700px) and (max-width: 1800px) {
  .manClimb {
    right: -25px !important;
  }
}

@media (min-width: 1801px) and (max-width: 1882px) {
  .manClimb {
    right: 0px !important;
  }
}

@media (min-width: 1883px) and (max-width: 1965px) {
  .manClimb {
    right: 20px !important;
  }
}

@media (min-width: 1966px) and (max-width: 2050px) {
  .manClimb {
    right: 40px !important;
  }
}

@media (min-width: 2051px) and (max-width: 2106px) {
  .manClimb {
    right: 60px !important;
  }
}

@media (min-width: 2107px) and (max-width: 2135px) {
  .manClimb {
    right: -80px !important;
  }
}

@media (min-width: 2136px) and (max-width: 2176px) {
  .manClimb {
    right: -70px !important;
  }
}

@media (min-width: 2177px) and (max-width: 2240px) {
  .manClimb {
    right: -60px !important;
  }
}

@media (min-width: 2241px) and (max-width: 2320px) {
  .manClimb {
    right: -45px !important;
  }
}

@media (min-width: 2321px) and (max-width: 2400px) {
  .manClimb {
    right: -30px !important;
  }
}

@media (min-width: 2401px) and (max-width: 2476px) {
  .manClimb {
    right: -10px !important;
  }
}

@media (min-width: 2477px) and (max-width: 2560px) {
  .manClimb {
    right: 10px !important;
  }
}
